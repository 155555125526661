<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao">
        <div class="zezhao_tip">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
      </div>
      <top-timer></top-timer>

      <div class="player listmain type-ssc yz5f game-yz5f game-ssc-zp" style="left: 200px">
        <div code="lst" class="data clst">
          <h3>总和、龙虎</h3>
          <ul>
            <li v-for="item in rowsData[0].arr" :key="item.ResultID" @click="setCurrentSelected(item)" :class="item.selected?'selected':''">
              <ol style="width: 44px" class="td_name">
                {{item.label}}
              </ol>
              <ol id="rate_19587" class="td_rate">
                {{item.Odds}}
              </ol>
              <ol class="td_cash">
                <input type="number" v-model="item.money" @click.stop="showOrHideCheck($event, item)" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num}}元
                    </li>

                    <li @click.stop="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

            <li>
              <ol class="td_empty" style="width: 100%; height: 17px"></ol>
            </li>
          </ul>
        </div>

        <!-- 第一球 -->
        <template v-for="(item,index) in rowsData">
          <div class="data " v-if="index>0" :class="'cZ'+index" :key="index">
            <h3>{{item.title}}</h3>
            <ul>
              <li :class="ele.selected?'selected':''" @click="setCurrentSelected(ele)" v-for="ele in item.arr" :key="ele.ResultID">
                <ol style="width: 44px" class="td_name">
                  {{isNumber(ele.label)?'':ele.label}}
                  <div v-if="isNumber(ele.label)" :class="'blueball ' + 'b' +ele.label">{{ele.label}}</div>

                </ol>
                <ol class="td_rate">
                  {{ele.Odds}}
                </ol>
                <ol class="td_cash">
                  <input type="number" v-model="ele.money" @click.stop="showOrHideCheck($event, ele)" />
                  <div class="quick-check" v-if="ele.b">
                    <ul>
                      <li v-for="qs in quickSelectedData" :key="qs.num" @click.stop="setCurrentName(ele, qs.num)">
                        下注{{ qs.num }}元
                      </li>

                      <li @click.stop="closeCheck(ele)">关闭</li>
                    </ul>
                  </div>
                </ol>
              </li>

            </ul>
          </div>
        </template>

      </div>
      <yushe />
      <!-- 下面单双 五球 -->
      <div class="dohand-padding dohand-b-position"></div>
      <div class="roadmap" style="">
        <!-- 五球 tab -->
        <table class="thlottab">
          <tbody>
            <tr>
              <td :class="qiuIndex==1?'selected':''" @click="changeQiu(1)">第一球</td>
              <td :class="qiuIndex==2?'selected':''" @click="changeQiu(2)">第二球</td>
              <td :class="qiuIndex==3?'selected':''" @click="changeQiu(3)">第三球</td>
              <td :class="qiuIndex==4?'selected':''" @click="changeQiu(4)">第四球</td>
              <td :class="qiuIndex==5?'selected':''" @click="changeQiu(5)">第五球</td>
            </tr>
          </tbody>
        </table>
        <!-- 单双 大小 总和 总和单双 总和大小tab -->
        <table class="ltbinfo">
          <tbody>
            <tr>
              <th :class="ltIndex==1?'selected':''" @click="changeLutuData(1)">单双</th>
              <!-- ds_d  ds_x -->
              <th :class="ltIndex==2?'selected':''" @click="changeLutuData(2)">大小</th>
              <!-- dx_d  dx_x -->
              <th :class="ltIndex==3?'selected':''" @click="changeLutuData(3)">总和</th>
              <!-- d   x -->
              <th :class="ltIndex==4?'selected':''" @click="changeLutuData(4)">总和单双</th>
              <!-- sum_dx_d sum_dx_s -->
              <th :class="ltIndex==5?'selected':''" @click="changeLutuData(5)">总和大小</th>
              <!-- sum_dx_d  sum_dx_x -->
            </tr>
          </tbody>
        </table>
        <!-- 单双 table -->
        <table colspan="0" id="lt_ds" cellspan="0" class="lttab" style="display: table">
          <tbody>
            <tr v-for="(item,index) in ltArr" :key="index">
              <td v-for="(ele,i) in item" :key="ele+i">
                <span v-if="!isNumber(ele)" :class="fontColor[ele]">{{ele}}</span>
                <span v-if="isNumber(ele)" :class="fontColor[ele>=23?'大':'小']">{{ele}}</span>
              </td>

            </tr>

          </tbody>
        </table>
        <!-- 单双 table  end -->
      </div>
    </div>

    <div class="other">
      <div class="zezhao" v-if="zezhao"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      ltArr: [],
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [
        { title: "总和、龙虎", arr: [] },
        { title: "第一球", arr: [] },
        { title: "第二球", arr: [] },
        { title: "第三球", arr: [] },
        { title: "第四球", arr: [] },
        { title: "第五球", arr: [] },
      ],
      label1: ["总和大", "总和小", "总和单", "总和双", "龙", "虎", "和"],
      label2: ["大", "小", "单", "双"],
      label3: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      // 第一球录图
      lutu1: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },

      lutu2: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu3: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu4: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
      // 第一球录图
      lutu5: {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      },
    };
  },
  components: {
    yushe,
    topTimer,
    yanzheng,
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: this.getLabel(ele.label),
                  Odds: ele.Odds,
                  title: item.title == "总和、龙虎" ? "" : item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });
          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    oddData: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 总和、龙虎
        let arr1 = resAArr.filter(
          (item) =>
            (item.ResultID >= 88 && item.ResultID <= 91) ||
            (item.ResultID >= 1767 && item.ResultID <= 1769)
        );
        arr1.forEach((item, index) => {
          (item.label = this.label1[index]), (item.b = false);
          item.selected = false;
          item.money = "";
          item.Odds = "--";
        });

        this.rowsData[0].arr = JSON.parse(JSON.stringify(arr1));
        // 五球  第一球
        let arr2 = this.filterData(resAArr, 53, 56, this.label2);
        let arr3 = this.filterData(resAArr, 1, 10, this.label3);

        this.rowsData[1].arr = [...arr2, ...arr3];
        // 第二球
        let arr4 = this.filterData(resAArr, 57, 60, this.label2);
        let arr5 = this.filterData(resAArr, 11, 20, this.label3);
        this.rowsData[2].arr = arr4.concat(arr5);
        // 第三球
        let arr6 = this.filterData(resAArr, 61, 64, this.label2);
        let arr7 = this.filterData(resAArr, 23, 32, this.label3);
        this.rowsData[3].arr = arr6.concat(arr7);
        // 第四球
        let arr8 = this.filterData(resAArr, 65, 68, this.label2);
        let arr9 = this.filterData(resAArr, 33, 42, this.label3);
        this.rowsData[4].arr = arr8.concat(arr9);
        // 第五球
        let arr10 = this.filterData(resAArr, 69, 72, this.label2);
        let arr11 = this.filterData(resAArr, 43, 52, this.label3);
        this.rowsData[5].arr = arr10.concat(arr11);
        // -----
        // console.log(this.rowsData, "rowData");
        // console.log(this.rowsData, "欢乐生肖---整合");
      },
    },
  },
  created() {},

  methods: {
    getKJHis() {
      this.lutu1 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu2 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu3 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu4 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      this.lutu5 = {
        arr1: [[], [], [], [], []], //单双
        arr2: [[], [], [], [], []], //大小
        arr3: [[], [], [], [], []], //总和
        arr4: [[], [], [], [], []], //总和单双
        arr5: [[], [], [], [], []], //总和大小
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        pagenum: 1,
        pagecount: 200,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            // 第一球----------
            let arr1_ds = [[]],
              arr1_dx = [[]],
              // 第二球
              arr2_ds = [[]],
              arr2_dx = [[]],
              // 第三球
              arr3_ds = [[]],
              arr3_dx = [[]],
              // 第四球
              arr4_ds = [[]],
              arr4_dx = [[]],
              // 第五球
              arr5_ds = [[]],
              arr5_dx = [[]],
              // ------------------
              arr_zh = [[]],
              arr_zhds = [[]],
              arr_zhdx = [[]];
            // --------------
            let list = resObj.data;

            list.forEach((element, index) => {
              element.codeArr = element.Opencode.split(",");
              // -第一球------------------------------------------------
              //  单双
              let q1 = element.codeArr[0];
              let ds1 = q1 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr1_ds, ds1);
              // 大小
              let dx1 = q1 >= 5 ? "大" : "小";
              this.initlutuData(arr1_dx, dx1);
              //  第二球--------------- ---------------------------------
              let q2 = element.codeArr[1];
              let ds2 = q2 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr2_ds, ds2, true);
              // 大小
              let dx2 = q2 >= 5 ? "大" : "小";
              this.initlutuData(arr2_dx, dx2);
              //  第三球--------------- ---------------------------------
              let q3 = element.codeArr[2];
              let ds3 = q3 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr3_ds, ds3);
              // 大小
              let dx3 = q3 >= 5 ? "大" : "小";
              this.initlutuData(arr3_dx, dx3);
              //  第四球--------------- ---------------------------------
              let q4 = element.codeArr[3];
              let ds4 = q4 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr4_ds, ds4);
              // 大小
              let dx4 = q4 >= 5 ? "大" : "小";
              this.initlutuData(arr4_dx, dx4);
              //  第五球--------------- ---------------------------------
              let q5 = element.codeArr[4];
              let ds5 = q5 % 2 == 0 ? "双" : "单";
              this.initlutuData(arr5_ds, ds5);
              // 大小
              let dx5 = q5 >= 5 ? "大" : "小";
              this.initlutuData(arr5_dx, dx5);
              // 总和
              let sum = 0;
              element.codeArr.forEach((num) => {
                sum += Number(num);
              });
              this.initlutuData(arr_zh, sum);
              // 总和大小
              let sumds = sum % 2 == 0 ? "双" : "单";
              let sumdx = sum >= 23 ? "大" : "小";
              this.initlutuData(arr_zhds, sumds);
              this.initlutuData(arr_zhdx, sumdx);
              // ----------------------------------------------------------
            });
            // 第一球 单双 大小 总和
            this.buquanArr(arr1_ds, this.lutu1.arr1);
            this.buquanArr(arr1_dx, this.lutu1.arr2);
            // 第二球 单双 大小 总和
            this.buquanArr(arr2_ds, this.lutu2.arr1);
            this.buquanArr(arr2_dx, this.lutu2.arr2);
            // 第三球 单双 大小 总和
            this.buquanArr(arr3_ds, this.lutu3.arr1);
            this.buquanArr(arr3_dx, this.lutu3.arr2);
            // 第四球 单双 大小 总和
            this.buquanArr(arr4_ds, this.lutu4.arr1);
            this.buquanArr(arr4_dx, this.lutu4.arr2);
            // 第五球 单双 大小 总和
            this.buquanArr(arr5_ds, this.lutu5.arr1);
            this.buquanArr(arr5_dx, this.lutu5.arr2);
            // 总和---------------------------------
            this.buquanArr(arr_zh, this.lutu1.arr3);
            this.buquanArr(arr_zh, this.lutu2.arr3);
            this.buquanArr(arr_zh, this.lutu3.arr3);
            this.buquanArr(arr_zh, this.lutu4.arr3);
            this.buquanArr(arr_zh, this.lutu5.arr3);

            this.buquanArr(arr_zhds, this.lutu1.arr4);
            this.buquanArr(arr_zhds, this.lutu2.arr4);
            this.buquanArr(arr_zhds, this.lutu3.arr4);
            this.buquanArr(arr_zhds, this.lutu4.arr4);
            this.buquanArr(arr_zhds, this.lutu5.arr4);

            this.buquanArr(arr_zhdx, this.lutu1.arr5);
            this.buquanArr(arr_zhdx, this.lutu2.arr5);
            this.buquanArr(arr_zhdx, this.lutu3.arr5);
            this.buquanArr(arr_zhdx, this.lutu4.arr5);
            this.buquanArr(arr_zhdx, this.lutu5.arr5);
            this.ltArr = this["lutu" + this.qiuIndex]["arr" + this.ltIndex];
            this.$forceUpdate();
          } else {
            this.ltArr = [];
          }
        } else {
          this.ltArr = [];
        }
      });
    },
    getLabel(str) {
      if (str == "龙") {
        str = "1-5[龙]";
      } else if (str == "虎") {
        str = "1-5[虎]";
      } else if (str == "和") {
        str = "1-5[和]";
      }
      return str;
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
/* 主体内容的样式 */
.listmain div.clst ul li {
  width: 25%;
}
</style>
